import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Tooltip } from "@airbus/components-react";
import { useDispatch, useSelector } from "react-redux";
import { getData , triggerHtmlFileTransfer} from "../../utils/data.request";
import { setPlasmaBanner } from "../../models/notificationModel/notificationActions";
import {
  Done,
  Close,
  Visibility,
  SettingsBackupRestore,
  CloudDownload,
  Send
} from "@airbus/icons/react";
import { apiDownloadZIP } from "../../models/reportModel/reportApi";
import {
  BUTTONS,
  ACTIONS,
  ACTION_STATUS,
  STATUS,
  MSG_SHOULD_SHOWON_BANNER,
  SECTION_DROPDOWN,
} from "../../config/dossier.status";
import {
  getStatus,
  updateStatus,
} from "../../models/dossierModel/dossierStatusApi";
import SlidingBox from "../SlidingBox/SlidingBox";
import "./Footer.scss";
import DailogBox from "../DialogBox/DialogBox";
import {TEVALUE, ONE} from "./constants";

const getIcon = (action) => {
  const iconMap = {
    [ACTIONS.ACCEPT]: <Done />,
    [ACTIONS.REJECT]: <Close />,
    [ACTIONS.REVIEW]: <Visibility />,
    [ACTIONS.DOWNLOAD]: <CloudDownload />,
    [ACTIONS.REPUDIATE]: <SettingsBackupRestore />,
    [ACTIONS.MOVETOPLASMA] : <Send />
  };
  return iconMap[action];
};
const Footer = () => {
  const { reportName } = useParams();
  const { loading, dossierStatus, userData, reports, accessRestriction, notification} = useSelector(
    (store) => store
  );
  const { isEditable , isSlidingBoxVisible,  isMPEFeedbackReceived  } = accessRestriction.smiedEditable;
  const { email, role } = userData.user;
  const dispatch = useDispatch();
  const [isSlidingBoxOpen, setIsSlidingBoxOpen] = useState(false);
  const [commentData, setCommentData] = useState({});
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modelProps, setModelProps] = useState({});
  const { dossierKey, rejectionLocalComments } = dossierStatus;
  const [dropDown, setDropDown] = useState([]);
  const url = window.location.href;

  useEffect(() => {
    dispatch(getStatus(reportName));
  }, [reportName, dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setCommentData({})
  }, [dossierStatus.status]);

  useEffect(() => {
    const latestCommentData = reports.toc && reports.toc.reduce((acc, item) => {
      const targetComment =
      rejectionLocalComments
      && rejectionLocalComments.find((comment) => comment.sectionName === item.label)
      acc[item.label] = targetComment ? targetComment.reason : '';
      return acc;
    }, {});
    setRejectionComments(latestCommentData);
  }, [rejectionLocalComments , reports.toc]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sources = reports.template && reports.template.sections.find((section) => section.label.includes(TEVALUE))?.contents[0]?.sources;
        const response = await getData(sources, reportName);
        if (response) {
          const uniqueHeadingsSet = new Set();
          const headings = response.data?.reduce((result, item) => {
            if (!uniqueHeadingsSet.has(item.heading)) {
              uniqueHeadingsSet.add(item.heading);
              result.push({ label: TEVALUE + " - " + item.heading });
            }
            return result;
          }, []);

          const indexOfTE = reports.toc.findIndex(item => item.label === TEVALUE);
          const firstHalf = reports.toc.slice(0, indexOfTE + 1);
          const secondHalf = reports.toc.slice(indexOfTE + 1);
          setDropDown([...firstHalf, ...(headings), ...secondHalf]);
        } else {
          setDropDown(reports.toc)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [reportName, reports.template, reports.toc]);

  const handleClick = (key) => {
    const actionMap = {
      [ACTIONS.ACCEPT]: checkConfirmation,
      [ACTIONS.REJECT]:checkConfirmation,
      [ACTIONS.REVIEW]: checkConfirmation,
      [ACTIONS.DOWNLOAD]: downloadPDF,
      [ACTIONS.REPUDIATE]: checkConfirmation,
      [ACTIONS.MOVETOPLASMA]: checkConfirmation,
    };
    window.scrollTo({ top: 0 });
    return actionMap[key](key);
  };
  const downloadPDF = () => {
    const statusInfo =
      STATUS.ACCEPTED === dossierStatus.status
        ? { status: STATUS.DELIVERED, email, dossierKey, dispatch }
        : {};
    dispatch(apiDownloadZIP(reportName, statusInfo));
  };
  const update = (action) => {
    const status = ACTION_STATUS[action];
    if (dossierStatus.mpeCommentsStatus === MSG_SHOULD_SHOWON_BANNER.CHAIR_PERSON_REJECT
      && STATUS.TECHNICAL_EVALUATION === dossierStatus.status
      ) {
        const latestMpeComment = dossierStatus.mpeComments.length > 0 && dossierStatus.mpeComments[0]
        const bannerAction = latestMpeComment
          && ((SECTION_DROPDOWN.MPE_COMMENT_KEY === latestMpeComment.sectionName && SECTION_DROPDOWN.MPE_NO_COMMENT_MSG === latestMpeComment.comment && MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITHOUT_COMMENT)
            || (SECTION_DROPDOWN.MPE_COMMENT_KEY === latestMpeComment.sectionName  && SECTION_DROPDOWN.MPE_COMMENT_MSG === latestMpeComment.comment && MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITH_COMMENT))
      dispatch(updateStatus({ status, email, dossierKey, mpeCommentsStatus: bannerAction }));
    }
    else {
      dispatch(updateStatus({ status, email, dossierKey }));
    }
    setIsSlidingBoxOpen(false);
    confirmModalClose();
  };

  const checkConfirmation = (key) => {
    if (!hasComments()
                && key[0] !== ACTIONS.ACCEPT
                && key[0] !== ACTIONS.REVIEW
                && key[0] !== ACTIONS.MOVETOPLASMA) {
      setIsSlidingBoxOpen(true);
    } else {
      confirmModalOpen(key);
    }
  }

  const hasComments = () => {
    return Object.keys(commentData).some((key) => !!commentData[key]);
  }

  const setRejectionComments = (comments) => {
    setCommentData(comments);
  }

  const triggerPlasmaFileTransfer = () => {
    const plasmaDossierKey = dossierStatus?.dossierKey;
    triggerHtmlFileTransfer({
      dossierKey: plasmaDossierKey,
      url: url,
    });
    dispatch(setPlasmaBanner(notification));
    confirmModalClose();
  }

  const confirmModalOpen = (key) => {
    const actionMap = {
      [ACTIONS.MOVETOPLASMA]: {
        onConfirm: triggerPlasmaFileTransfer,
        DialogBody:
          dossierStatus.htmlFileStatus == "INIT"
            ? "Are you sure to transfer the file to PLASMA ?"
            : "File has been already transferred, do you want initiate transfer again to PLASMA ?",
      },
      [ACTIONS.ACCEPT]: {
        onConfirm: () => update (key),
        DialogBody: 'Do you want to accept the dossier ?'
      },
      [ACTIONS.REJECT]: {
        onConfirm: updateComments,
        DialogBody: 'Do you want to reject the dossier ?'
      },
      [ACTIONS.REPUDIATE]: {
        onConfirm: updateComments,
        DialogBody: 'Do you want to redo the dossier ?'
      },
      [ACTIONS.REVIEW]: {
        onConfirm: () => update (key),
        DialogBody: isMPEFeedbackReceived ? 'Do you want to submit the dossier for Acceptance/Validation ?'
        :'This dossier has not been commented. Are you sure you want to submit the dossier for Acceptance/Validation without comments?'
      },
    };
    setModelProps(actionMap[key]);
    setConfirmModalOpen(true);
  };

  const confirmModalClose = () => {
    setConfirmModalOpen(false);
    setModelProps({});
  };

  const updateComments = () => {
    // setLoading(true)
    const status = STATUS.TECHNICAL_EVALUATION
    const commentsArray = Object.entries(commentData).map(([key, value]) => ({ sectionName: key, reason: value || '' }))
    const updatedComments = [...commentsArray.filter(c => c.reason), ...dossierStatus.comments]
    const rejctedByChair = MSG_SHOULD_SHOWON_BANNER.CHAIR_PERSON_REJECT
    dispatch(updateStatus({ status, email, dossierKey, comments: updatedComments , mpeCommentsStatus:rejctedByChair}));
    setIsSlidingBoxOpen(false);
    confirmModalClose();
  }

  const renderConfirmModalBox = () => {
    return isConfirmModalOpen ? (
      <DailogBox
        className="confirm-modal"
        isOpen={isConfirmModalOpen}
        onConfirm={modelProps.onConfirm}
        onClose={confirmModalClose}
        buttonName={'Confirm'}
        body={modelProps.DialogBody}
        title={'Are you sure ?'}
      />
    ) : (
      false
    );
  };

  const isDisabled = () => {
    const { zipFilenames, zipDownload, getDossierStatus, updateDossierStatus } =
      loading;
    return (
      !reportName ||
      !dossierStatus.dossierKey ||
      zipFilenames ||
      zipDownload ||
      getDossierStatus ||
      updateDossierStatus
    );
  };
  // status and role based access filters
  const buttons = BUTTONS.filter(
    (button) =>
      button.roles.indexOf(role) > -1 &&
      button.status.indexOf(dossierStatus.status) > -1
  );
  const fixedFooterStatus = [STATUS.DELIVERED, STATUS.ACCEPTED];
  const clsName =
    fixedFooterStatus.indexOf(dossierStatus.status) > -1 ? " fixed" : "";
  const renderButtons = () => {
    return buttons.map((button) => (
     <Tooltip title={button.toolTip || button.label} key={button.key} placement="top">
        <Button
          className={`${button.key}-btn`}
          variant="primary"
          size="small"
          disabled={isDisabled()}
          key={button.key}
          icon={getIcon(button.key)}
          onClick={() => handleClick(button.key)}
        >
          <span className="btn-txt">{button.label}</span>
        </Button>
     </Tooltip>
    ));
  };
  //dossier status in which box should be visible
  // const isSlidingBoxVisible = [STATUS.TECHNICAL_EVALUATION, STATUS.REVIEW].includes(dossierStatus.status)
  return (
    <div className={`exclude-print`}>
      {renderConfirmModalBox()}
      {isSlidingBoxVisible && (
        <SlidingBox
          isSlidingBoxOpen={isSlidingBoxOpen}
          isEditable={isEditable}
          comments={dossierStatus.comments}
          mpeComments={dossierStatus.mpeComments}
          mpeLocalComments={dossierStatus.mpeLocalComments}
          toc={dossierStatus.statusdbVersion === ONE ? reports.toc : dropDown}
          dossierKey={dossierStatus.dossierKey}
          status={dossierStatus.status}
          email={email}
          setRejectionComments ={setRejectionComments}
          rejectionLocalComments = {dossierStatus.rejectionLocalComments}
        />
      )}
      {buttons.length ? (
        <div className={`footer-cls${clsName}`}>{renderButtons()}</div>
      ) : (
        false
      )}
    </div>
  );
};

export default Footer;
